:root {
  --color-scheme: dark;
  --primary-color:  #FF88FF;
  --light-grey-color: #FFFFFF10;
  --alpha-contrast-color: #FFFFFF40;
  --first-text-color: #FAFAFA;
  --second-text-color: #C7C7C7;
  --third-text-color: #797979;
  --fourth-text-color: #FFFFFF;

  --high-contrast-text-color: #FFF;
  --high-contrast-bg-color: #000;

  --body-bg-color: #070707;
  --body-text-color: #FAFAFA;
  --alpha-bg-color: #000000CC;

  --component-label-color: #F6F3F6;
  --component-label-disabled-color: #B3A8B3;
  --component-badge-bg-color: #161216;
  --component-badge-border-color: #4D424D;
  --component-bg-tooltip-color: #4D424D;
  --component-bg-tag-color: #2C262C;
  --component-modal-color: #2C262C;
  --component-bg-color: #0E0E0E;
  --component-dropmenu-bg-color: #2C262C;
  --component-dropmenu-bg-item-color: #4D424D;
  --component-dropmenu-text-color: #F6F3F6;
  --component-dropmenu-shadow-color: #ffffff08;
  --component-card-bg-item-color: #2C262C;
  --component-bg-color-negative: #470000;
  --component-border-color: #272727;
  --component-text-color: #FFF;
  --sub-component-bg-color: #272727;
  --sub-component-border-color: #272727;
  --sub-component-hover-border-color: #7E009F;
  --sub-component-focus-border-color: #C400E0;
  --sub-component-disabled-border-color: #161216;
  --sub-component-negative-border-color: #950024;
  --sub-component-negative-background-color: #660019;

  --custom-scrollbar-track-color: #0E0E0E;
  --custom-scrollbar-thumb-color: #E500FF10;
  --custom-scrollbar-hover-color: #E500FFff;
  --custom-button-hover-color: #f57ef561;

  --color-info:#BAA5FD;
  --color-secondary-info: #F6F3F6;
  --color-main:#FF88FF;
  --color-negative: #FF85A5;
  --color-positive: #6EE95D;
  --color-warning: #FFDA4C;

  --background-info: #180047;
  --background-main:  #29003D;
  --background-destructive:#470000;
  --background-positive:  #003300;
  --background-warning:  #420000;

  --empty-background:#2c262c;
  --empty-border:#ff50ff;
  --empty-text:#fff;
  --empty-paragraph:#F6F3F6;
  --empty-main-color: #FF88FF;
  --empty-base-color: #161216;
  --empty-neutral-color: #4D424D;

  --logo-main-color: #E500FF;
  --logo-base-color: #FFFFFF;

  --avatar-bg-color: #4D424D;
  --avatar-stroke-color: #B3A8B3;

  --text-color-primary: #42005C;
  --text-color-secondary: #fff;
  --text-color-destructive: #660019;
  --text-color-negative: #FF85A5;

  --background-primary:#ff88ff;
  --background-secondary:#29003d;
  --background-destructive:#ff85a5;
  --background-negative:#470000;

  --background-primary-hover:#ff50ff;
  --background-secondary-hover:#ff50ff;
  --background-third-hover:#42005C;
  --background-destructive-hover:#fb4170;
  --background-negative-hover:#660019;

  --background-primary-active:#dc76dc;
  --background-secondary-active:#cd26e5;
  --background-destructive-active:#dc7490;
  --background-negative-active:#470000;

  --border-primary-active:#7E009F;
  --border-secondary-active:#7E009F;
  --border-destructive-active:#950024;
  --border-negative-active:#950024;

  --border-primary:#cd26e5;
  --border-secondary:#8207a2;
  --border-destructive:#840725;
  --border-negative:#840826;

  --button-shadow-color: #00000033;
  --border-upload:#4D424D;
  --text-upload:#F6F3F6;

  --background-pagination: #29003d;
  --border-pagination:#7E009F;
  --color-pagination:#F6F3F6;
  --color-active-pagination: #ffff;
  --color-separator: #F6F3F6;

  --text-number-color: #B3A8B3;
  --tab-background:#42005C;
  --tab-background-hover:#7E009F;
  --tab-text-hover:#fff;

}


[data-theme='light'] {
  --color-scheme: light;
  --primary-color: #7E009F;
  --light-grey-color: #00000010;
  --alpha-contrast-color: #00000045;
  --first-text-color: #0E0E0E;
  --second-text-color: #272727;
  --third-text-color: #797979;
  --fourth-text-color: #161216;

  --high-contrast-text-color: #000;
  --high-contrast-bg-color: #FFF;

  --body-bg-color: #F6F3F6;
  --body-text-color: #333;
  --alpha-bg-color: #FFFFFFCC;

  --component-label-color: #4D424D;
  --component-label-disabled-color:  #968896;
  --component-badge-bg-color: #FFFFFF;
  --component-badge-border-color: #EDE8ED;
  --component-bg-tooltip-color: #2C262C;
  --component-bg-tag-color: #F6F3F6;
  --component-bg-color: #FAFAFA;
  --component-dropmenu-bg-color: #FFFFFF;
  --component-modal-color:#F9F9F9;
  --component-dropmenu-bg-item-color: #F6F3F6;
  --component-dropmenu-text-color: #161216;
  --component-dropmenu-shadow-color: #00000008;
  --component-card-bg-item-color: #FFFFFF;
  --component-bg-color-negative: #FFE0ED;
  --component-border-color: none;
  --component-text-color: #272727;
  --sub-component-bg-color: #fdf5fe;
  --sub-component-border-color: #dfdfdf;
  --sub-component-hover-border-color: #ffc2ff;
  --sub-component-focus-border-color: #C400E0;
  --sub-component-disabled-border-color: #F6F3F6;
  --sub-component-negative-border-color: #FFC2DB;
  --sub-component-negative-background-color: #FFC2DB;

  --custom-scrollbar-track-color: #F0F0F0;
  --custom-scrollbar-thumb-color: #DFDFDF;
  --custom-scrollbar-hover-color: #E500FF50;
  --custom-button-hover-color: #f57ef5a1;

  --color-info: #340099;
  --color-secondary-info: #340099;
  --color-main:#7E009F;
  --color-negative:#950024;
  --color-positive: #006600;
  --color-warning: #7A3F00;

  --background-info: #E6E1FE;
  --background-main:  #FFE0FF;
  --background-destructive:#FFE0ED;
  --background-positive: #D5FBD0;
  --background-warning:  #FFF4CC;

  --empty-background:#EDE8ED;
  --empty-border:#c400e0;
  --empty-text:#161216;
  --empty-paragraph:#4D424D;
  --empty-main-color: #7E009F;
  --empty-base-color: #FFF;
  --empty-neutral-color:#F6F3F6;

  --empty-secondary-color: #fff;

  --logo-main-color: #E500FF;
  --logo-base-color: #636466;

  --avatar-bg-color: #F6F3F6;
  --avatar-stroke-color: #968896;

  --text-color-primary: #FFF;
  --text-color-secondary: #7E009F;
  --text-color-destructive: #fff;
  --text-color-negative: #950024;

  --background-primary:#7E009F;
  --background-secondary:#FFF;
  --background-destructive:#950024;
  --background-negative:#FFFFFF;

  --background-primary-hover:#42005C;
  --background-secondary-hover:#ffe0ff;
  --background-third-hover:#FFC2FF;
  --background-destructive-hover:#a52645;
  --background-negative-hover:#ffe0ed;

  --background-primary-active:#9126ad;
  --background-secondary-active:#FFF;
  --background-destructive-active:#a52645;
  --background-negative-active:#fff;

  --border-primary-active:#C400E0;
  --border-secondary-active:#C400E0;
  --border-destructive-active:#950024;
  --border-negative-active:#BC003E;

  --border-primary:#cd26e5;
  --border-secondary:#ede8ed;
  --border-negative:#ede8ed;

  --button-shadow-color: #0000000d;
  --border-upload:#ede8ed;
  --text-upload:#7E009F;

  --background-pagination: #FFFFFF;
  --border-pagination:#EDE8ED;
  --color-pagination:#7E009F;
  --color-active-pagination: #161216;
  --color-separator: #968896;

  --text-number-color: #4D424D;
  --tab-background:#E4C7F2;
  --tab-background-hover:#CA8FE6;
  --tab-text-hover:#42005C;

}
