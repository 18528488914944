.richtext-editor,
.richtext-reader {

  body {
    margin: 0;
    background: #ddd;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular",
      sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .other h2 {
    font-size: 18px;
    color: #444;
    margin-bottom: 7px;
  }

  .other a {
    color: #777;
    text-decoration: underline;
    font-size: 16px;
  }

  .other ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .App {
    font-family: sans-serif;
    text-align: center;
  }

  h1 {
    font-size: 24px;
    color: var(--component-text-color);
  }

  .ltr {
    text-align: left;
  }

  .rtl {
    text-align: right;
  }

  .editor-container {
    max-width: 100%;
    color: var(--component-text-color);
    position: sticky;
    top: 20px;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 16px;
    border: 1px solid var(--sub-component-border-color);
    border-radius: 4px;

    &:hover {
      border: 1px solid var(--sub-component-hover-border-color);
    }

    &:focus {
      outline: 2px solid var(--sub-component-focus-border-color);
    }
  }

  .editor-inner {
    background: var(--component-bg-color);
    position: relative;
    border: 1px solid var(--component-border-color);
    max-height: 80vh;
    overflow-y: auto;
  }

  .editor-input {
    min-height: 150px;
    resize: none;
    font-size: 16px;
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: 15px 24px;
    caret-color: var(--component-text-color);
  }

  .editor-placeholder {
    color: var(--third-text-color);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 15px;
    left: 24px;
    font-size: 16px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
  }

  .editor-text-bold {
    font-weight: bold;
  }

  .editor-text-italic {
    font-style: italic;
  }

  .editor-text-underline {
    text-decoration: underline;
  }

  .editor-text-strikethrough {
    text-decoration: line-through;
  }

  .editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
  }

  .editor-text-code {
    background-color: var(--alpha-contrast-color);
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
  }

  .editor-link {
    color: var(--primary-color);
    text-decoration: none;
  }

  .tree-view-output {
    display: block;
    background: var(--component-bg-color);
    color: var(--component-text-color);
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
    margin: 1px auto 10px auto;
    max-height: 250px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: auto;
    line-height: 14px;
  }

  .editor-code {
    background-color: var(--light-grey-color);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    /* white-space: pre; */
    overflow-x: auto;
    position: relative;
  }

  .editor-code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: var(--alpha-contrast-color);
    left: 0;
    top: 0;
    border-right: 1px solid var(--component-border-color);
    padding: 8px;
    color: var(--component-text-color);
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
  }

  .editor-code:after {
    content: attr(data-highlight-language);
    top: 0;
    right: 3px;
    padding: 3px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    color: var(--alpha-contrast-color);
  }

  .editor-tokenComment {
    color: slategray;
  }

  .editor-tokenPunctuation {
    color: #999;
  }

  .editor-tokenProperty {
    color: #905;
  }

  .editor-tokenSelector {
    color: #690;
  }

  .editor-tokenOperator {
    color: #9a6e3a;
  }

  .editor-tokenAttr {
    color: #07a;
  }

  .editor-tokenVariable {
    color: #e90;
  }

  .editor-tokenFunction {
    color: #dd4a68;
  }

  .editor-paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;
  }

  .editor-paragraph:last-child {
    margin-bottom: 0;
  }

  .editor-heading-h1 {
    font-size: 24px;
    color: var(--first-text-color);
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
  }

  .editor-heading-h2 {
    font-size: 15px;
    color: var(--second-text-color);
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    text-transform: uppercase;
  }

  .editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: var(--third-text-color);
    border-left-color: var(--third-text-color);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
  }

  .editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }

  .editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }

  .editor-listitem {
    margin: 8px 32px 8px 32px;
  }

  .editor-nested-listitem {
    list-style-type: none;
  }

  pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
  }

  pre::-webkit-scrollbar-thumb {
    background: var(--custom-scrollbar-thumb-color);
  }

  .debug-timetravel-panel {
    overflow: hidden;
    padding: 0 0 10px 0;
    margin: auto;
    display: flex;
  }

  .debug-timetravel-panel-slider {
    padding: 0;
    flex: 8;
  }

  .debug-timetravel-panel-button {
    padding: 0;
    border: 0;
    background: none;
    flex: 1;
    color: var(--component-text-color);
    font-size: 12px;
  }

  .debug-timetravel-panel-button:hover {
    text-decoration: underline;
  }

  .debug-timetravel-button {
    border: 0;
    padding: 0;
    font-size: 12px;
    top: 10px;
    right: 15px;
    position: absolute;
    background: none;
    color: var(--component-text-color);
  }

  .debug-timetravel-button:hover {
    text-decoration: underline;
  }

  .emoji {
    color: transparent;
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin: 0 -1px;
  }

  .emoji-inner {
    padding: 0 0.15em;
  }

  .emoji-inner::selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
  }

  .emoji-inner::moz-selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
  }

  .emoji.happysmile {
    background-image: url("images/emoji/1F642.png");
  }

  .toolbar {
    display: flex;
    margin-bottom: 1px;
    background: var(--sub-component-border-color);
    padding: 4px;
    vertical-align: middle;
    overflow: auto;

    button.toolbar-item {
      border: 0;
      display: flex;
      background: none;
      border-radius: 10px;
      padding: 8px;
      cursor: pointer;
      vertical-align: middle;
    }

    button.toolbar-item:disabled {
      cursor: not-allowed;
    }

    button.toolbar-item.spaced {
      margin-right: 2px;
    }

    button.toolbar-item i.format {
      background-size: contain;
      display: inline-block;
      height: 18px;
      width: 18px;
      margin-top: 2px;
      vertical-align: -0.25em;
      display: flex;
      opacity: 0.6;
    }

    button.toolbar-item:disabled i.format {
      opacity: 0.2;
    }

    button.toolbar-item.active {
      background-color: rgba(223, 232, 250, 0.3);
    }

    button.toolbar-item.active i {
      opacity: 1;
    }

    .toolbar-item:hover:not([disabled]) {
      background-color: var(--alpha-bg-color);
    }

    .divider {
      width: 1px;
      background-color: var(--alpha-bg-color);
      margin: 0 4px;
    }

    select.toolbar-item {
      border: 0;
      display: flex;
      background: none;
      border-radius: 10px;
      padding: 8px;
      vertical-align: middle;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 70px;
      font-size: 16px;
      color: #777;
      text-overflow: ellipsis;
    }

    select.code-language {
      text-transform: capitalize;
      width: 130px;
    }

    .toolbar-item .text {
      display: flex;
      line-height: 20px;
      width: 200px;
      vertical-align: middle;
      font-size: 16px;
      color: #777;
      text-overflow: ellipsis;
      width: 70px;
      overflow: hidden;
      height: 20px;
      text-align: left;
    }

    .toolbar-item .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 8px;
      line-height: 16px;
      background-size: contain;
    }

    i.chevron-down {
      margin-top: 3px;
      width: 16px;
      height: 16px;
      display: flex;
      user-select: none;
    }

    i.chevron-down.inside {
      width: 16px;
      height: 16px;
      display: flex;
      margin-left: -25px;
      margin-top: 11px;
      margin-right: 10px;
      pointer-events: none;
    }
  }

}

i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url(images/icons/chevron-down.svg);
}

.block-controls {
  button:hover {
    background-color: #efefef;
  }

  button:focus-visible {
    border-color: blue;
  }

  span.block-type {
    background-size: contain;
    display: block;
    width: 18px;
    height: 18px;
    margin: 2px;

    &.paragraph {
      background-image: url(images/icons/text-paragraph.svg);
    }

    &.h1 {
      background-image: url(images/icons/type-h1.svg);
    }

    &.h2 {
      background-image: url(images/icons/type-h2.svg);
    }

    &.quote {
      background-image: url(images/icons/chat-square-quote.svg);
    }

    &.ul {
      background-image: url(images/icons/list-ul.svg);
    }

    &.ol {
      background-image: url(images/icons/list-ol.svg);
    }

    &.code {
      background-image: url(images/icons/code.svg);
    }
  }
}

i.undo {
  background-image: url(images/icons/arrow-counterclockwise.svg);
}

i.redo {
  background-image: url(images/icons/arrow-clockwise.svg);
}

.icon.paragraph {
  background-image: url(images/icons/text-paragraph.svg);
}

.icon.large-heading,
.icon.h1 {
  background-image: url(images/icons/type-h1.svg);
}

.icon.small-heading,
.icon.h2 {
  background-image: url(images/icons/type-h2.svg);
}

.icon.bullet-list,
.icon.ul {
  background-image: url(images/icons/list-ul.svg);
}

.icon.numbered-list,
.icon.ol {
  background-image: url(images/icons/list-ol.svg);
}

.icon.quote {
  background-image: url(images/icons/chat-square-quote.svg);
}

.icon.code {
  background-image: url(images/icons/code.svg);
}

i.bold {
  background-image: url(images/icons/type-bold.svg);
}

i.italic {
  background-image: url(images/icons/type-italic.svg);
}

i.underline {
  background-image: url(images/icons/type-underline.svg);
}

i.strikethrough {
  background-image: url(images/icons/type-strikethrough.svg);
}

i.code {
  background-image: url(images/icons/code.svg);
}

i.link {
  background-image: url(images/icons/link.svg);
}

i.left-align {
  background-image: url(images/icons/text-left.svg);
}

i.center-align {
  background-image: url(images/icons/text-center.svg);
}

i.right-align {
  background-image: url(images/icons/text-right.svg);
}

i.justify-align {
  background-image: url(images/icons/justify.svg);
}

.editor-toolbar-dropdown {
  z-index: 5;
  display: block;
  position: absolute;
  box-shadow: inset -1px -1px 1px var(--component-dropmenu-shadow-color);
  filter: drop-shadow(1px 2px 2px var(--component-dropmenu-shadow-color)) drop-shadow(2px 6px 18px var(--component-dropmenu-shadow-color));
  border-radius: 8px;
  min-width: 100px;
  min-height: 40px;
  background-color: var(--component-dropmenu-bg-color);
  padding: 8px 0;

  .item {
    margin: 0;
    padding: 8px 16px;
    cursor: pointer;
    line-height: 20px;
    font-size: 16px;
    display: flex;
    background-color: var(--component-dropmenu-bg-color);
    color: var(--component-dropmenu-text-color);
    border-radius: 0;
    border: 0;
    min-width: 268px;
    width: 100%;

    .active {
      display: flex;
      width: 20px;
      height: 20px;
      background-size: contain;
    }

    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 8px;
    }

    &:hover {
      background-color: var(--component-dropmenu-bg-item-color);
    }

    .text {
      display: flex;
      line-height: 20px;
      flex-grow: 1;
      width: 200px;
    }

    .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 12px;
      line-height: 16px;
      background-size: contain;
    }
  }
}

.link-editor {
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;

  .link-input {
    display: block;
    width: calc(100% - 24px);
    box-sizing: border-box;
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: #ddd;
    font-size: 15px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
  }

  div.link-edit {
    background-image: url(images/icons/pencil-fill.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  .link-input a {
    color: var(--primary-color);
    text-decoration: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    text-overflow: ellipsis;
  }

  .link-input a:hover {
    text-decoration: underline;
  }

  .button {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;
  }

  .button.hovered {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #ddd;
  }
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

// THEME EXCEPTIONS

[data-theme=dark] {

  .richtext-editor {

    .toolbar button.toolbar-item {

      i,
      .icon {
        filter: invert(1);
      }
    }
  }

  .editor-toolbar-dropdown .item {

    i,
    .icon {
      filter: invert(1);
    }
  }
}
