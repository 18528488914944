/* reset --------------------- */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  background-color: var(--body-bg-color);
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: var(--body-text-color);
}
:focus-visible {
  // outline-color: var(--component-border-color);
  outline-color: var(--primary-color);
  outline: none;
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin-bottom: 10px;
}

h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
}

h2 {
  font-size: 32px;
  line-height: 36px;
  margin-top: 20px;
}

h3 {
  font-size: 24px;
  line-height: 30px;
  margin-top: 20px;
}

h4 {
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
}

h5 {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
}

h6 {
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

blockquote {
  margin: 0 0 10px 0;
  padding: 10px 20px;
  border-left: 5px solid #e2e2e2;
  font-size: 18px;
  line-height: 22px;
}

figure {
  margin: 0;
  margin-bottom: 10px;
}

figcaption {
  margin-top: 5px;
  text-align: center;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 44px;
  font-weight: bold;
}

a {
  text-decoration: none;
}

/* reset --------------------- */

/* custom --------------------- */
a {
  color: var(--component-text-color);
}

a:hover {
  color: var(--primary-color);
}

a span.material-symbols-outlined {
  vertical-align: text-bottom;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

/* global */
.highlight-text {
  color: var(--primary-color);
}

.form-block {
  margin: 0 0 15px;
  margin-top: 12px;
  margin-bottom: 24px;
}

/* global */

@media screen and (max-width: 479px) {
  input {
    // prevent iphone zooming
    font-size: 16px;
  }
}

input[type="checkbox"] {
  display: inline;
  width: auto;
  height: auto;
  margin: 0 6px 0 0;
}

input {
  color-scheme: var(--color-scheme);
}

.checkbox label{
  display: flex;
  align-items: center;
  font-weight: normal;
  margin: 0px;
}
.checkbox input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0px;
  width: 24px;
  height: 24px;
  border: 1px solid var(--component-badge-border-color);
  border-radius: 4px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
  display: grid;
  place-content: center;
}

.checkbox input[type="checkbox"]:disabled {
  cursor: not-allowed;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--component-badge-border-color);
  border: none;
}

.checkbox input[type="checkbox"]::after {
  content: "";
  width: 18px;
  height: 18px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  margin-bottom: 2px;
  background-image: url('../assets/check.svg');

}

.checkboxDark input[type="checkbox"]::after {
  content: "";
  width: 18px;
  height: 18px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  margin-bottom: 2px;
  background-image: url('../assets/check-dark.svg') ;
}

.checkbox input[type="checkbox"]:checked::after {
  transform: scale(.9);
}

.empty-list-message {
  display: flex;
  flex-grow: 0.5;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding: 20px;
  text-align: center;
  margin: 20px;
}

.icon-filled {
  font-variation-settings: " 'FILL' 1 " !important;
}


//error pages

.error-wrapper {
  color: inherit;
  background: inherit;
  font-family: inherit, sans-serif;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 479px) {
    height: auto;
  }

  h1 {
    display: inline-block;
    border-right: 1px solid var(--alpha-contrast-color);
    margin: 0;
    margin-right: 20px;
    padding: 10px 23px 10px 0;
    font-size: 24px;
    font-weight: 500;
    vertical-align: top;

    @media screen and (max-width: 479px) {
      display: block;
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  h2 {
    font-size: 14px;
    font-weight: normal;
    line-height: inherit;
    margin: 0;
    padding: 0;
  }

  .text {
    display: inline-block;
    text-align: left;
    line-height: 49px;
    height: 49px;
    vertical-align: middle;
  }
}

body {
  overflow-x: hidden;
}

.react-joyride {
  position: absolute;
}
