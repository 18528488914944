// TYPOGRAPHY
.white-text {
  color: var(--high-contrast-text-color);
}
.white-off-text {
  color: var(--second-text-color);
}
.accent-text {
  color: var(--primary-color);
}
.light-grey-text {
  color: var(--third-text-color);
}
.ps-h1-bold {
  font-size: 38px;
  line-height: 44px;
  font-weight: 700;
}
.ps-p1-bold {
  font-weight: 700;
}
.ps-p1-normal {
  font-weight: 400;
}
.ps-p2-normal {
  font-size: 12px;
}
.ps-h3-bold {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}
.ps-h4-bold {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

// BUTTON
.accent-button {
  padding: 9px 15px;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  color: var(--high-contrast-bg-color);
  background-color:  var(--primary-color);
}

.form-error {
  font-size: 12px;
  background-color: var(--sub-component-bg-color);
  padding: 3px 6px;
  color: var(--primary-color);
  margin-bottom: 16px;
  margin-top: -16px;
  min-height: 25px;

  &~.icon {
    bottom: calc(70px);
  }
}


//TABS
.tabs {
  position: relative;

  .tabs-menu {
    position: relative;


    @media screen and (max-width: 479px) {
      display: grid;
      grid-auto-columns: 1fr;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr ;
      -ms-grid-rows: auto;
      grid-template-rows: auto;
      margin-top: 0;
    }

    .tab-item {
      border: none;

      position: relative;
      display: inline-block;
      vertical-align: top;
      text-decoration: none;
      text-align: left;
      cursor: pointer;
      color: inherit;
      max-width: 100%;
      font-size: 14px;
      font-family: inherit;
      font-weight: inherit;
      bottom: -1px;
      background: none;
      padding: 0px 16px;
      border-radius: 8px;
      height: 35px;

      @media screen and (max-width: 479px) {
        text-align: start;
        margin-bottom: 0;
        padding: 8px 22px;
      }

      &[data-state="active"], &.active {
        background: var(--tab-background);
        color: var(--primary-color);
        opacity: 1;

        &:hover{
          background: var(--tab-background-hover);
        }
        &:focus-visible{
          background: var(--tab-background);
          border: 2.5px dashed #FF50FF;
          outline: none;

        }
      }

      .tab-icon {
        color: var(--primary-color);
        font-size: 16px;
        vertical-align: text-top;
        margin-right: 3px;
      }
    }
  }
}

.danger-button {
  border: 0;
  padding: 6px 12px
}
