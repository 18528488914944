@keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + 25px))
  }

  to {
    transform: translateX(0)
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x))
  }

  to {
    transform: translateX(calc(100% + 25px))
  }
}

.viewport {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  padding: 25px;
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
}


.root {
  background-color: var(--background-positive);
  box-shadow: 0 0 20px 0 rgb(255 166 0 / 10%);
  padding: 12px;
  display: grid;
  grid-template-areas: "title action" "description action" "list action";
  grid-template-columns: auto max-content;
  column-gap: 15px;
  align-items: flex-start;
  grid-auto-flow: dense;
  grid-row: 1;
  margin-top: -83px;
  opacity: 0.5;
  border-left: 4px solid var(--color-positive);

  a {
    font-size: 13px;
    &:hover {
      opacity: .7;
      color: var(--color-positive);
    }
  }
  span{
    font-size: 14px;
  }

  &>*,
  .close-button {
    visibility: hidden !important;
  }

  &:first-of-type,
  &:hover {
    opacity: 1;
    z-index: 100 !important;

    &>* {
      visibility: visible !important;
    }
  }



  @media (prefers-reduced-motion: no-preference) {
    &[data-state="open"] {
      animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }

    &[data-state="closed"] {
      animation: hide 100ms ease-in forwards;
    }

    &[data-swipe="move"] {
      transform: translateX(var(--radix-toast-swipe-move-x));
    }

    &[data-swipe="cancel"] {
      transform: translateX(0);
      transition: transform 200ms ease-out;
    }

    &[data-swipe="end"] {
      animation: swipeOut 100ms ease-out forwards;
    }
  }
}

.list_item{
  padding: 26px 15px;
}
.root_info {
  border-color: var(--color-info);
  background-color: var(--background-info);
}

.root_main {
  border-color: var(--color-main);
  background-color: var(--background-main)
}

.root_negative {
  border-color: var(--color-negative);
  background-color: var(--background-negative);
}

.root_positive {
  border-color: var(--color-positive);
  background-color: var(--background-positive);
}


.title {
  align-items: flex-start;
  display: flex;
  grid-area: title;
  font-weight: 400;
  color: var(--color-positive);
  font-size: 16px;
  line-height: 20px;

  .icon * {
    font-size: 20px;
    margin-right: 8px;
    margin-top: 2px;
  }
}

.description {
  grid-area: description;
  margin: 0;
  color: var(--color-positive);
  font-size: 14px;
  line-height: 20px;
  margin-left: 25px;
  max-width: 75%;
  a {
    all: unset;
    text-decoration: underline;
    align-items: center;
    justify-content: center;
    align-self: center;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    padding-right: 3px;
    cursor: pointer;
  }
  ul {
    list-style: disc;
    margin: 5px -24px;
    font-size: 13px;
  }

}

.close_button {
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 0.8;
  font-weight: 500;
  height: 24px;
  width: 24px;
  color: var(--color-positive);

  cursor: pointer;

  @media screen and (min-width: 992px) {
    &:hover {

      opacity: .7;

    }
  }
}

.list {
  grid-area: list;
  color: var(--color-positive);
}

.title_info,
.description_info,
.close_button_info {
  color: var(--color-info);

  a {
    color: var(--color-info);

    &:hover {
      opacity: .7;
      color: var(--color-info);
    }
  }
}

.title_main,
.description_main,
.list_main,
.close_button_main {
  color: var(--color-main);

  a {
    color: var(--color-main);

    &:hover {
      opacity: .7;
      color: var(--color-main);
    }
  }
}

.title_negative,
.description_negative,
.list_negative,
.close_button_negative {
  color: var(--color-negative);

  a {
    color: var(--color-negative);

    &:hover {
      opacity: .7;
      color: var(--color-negative);
    }
  }
}

.title_positive,
.description_positive,
.list_positive,
.close_button_positive {
  color: var(--color-positive);

  a {
    color: var(--color-positive);

    &:hover {
      opacity: .7;
      color: var(--color-positive);
    }
  }
}


.action {
  grid-area: action;
}

