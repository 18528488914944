/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--custom-scrollbar-track-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 1px;
  background: var(--custom-scrollbar-thumb-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  display: inherit;
  background: var(--custom-scrollbar-hover-color);
}
